// explicitly naming exports because webpack doesn't tree-shake 'export * from'
// correctly
export {
  defaultSize, initialState, reducer, SET_MOBILE_DETECT, setMobileDetect,
} from './redux'
export {
  MediaQueryWrapper, responsiveWrapper, PhoneScreen, TabletScreen,
  DesktopScreen, MobileScreen, PhoneScreenHidden, TabletScreenHidden,
  DesktopScreenHidden, MobileScreenHidden, XsScreen, SmScreen, MdScreen,
  LgScreen, XsScreenHidden, SmScreenHidden, MdScreenHidden, LgScreenHidden,
} from './components'
export { breakPoints, defaultSizes } from './defaults'
export { mobileParser } from './parser'
